<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'ChatroomIndex',
              }"
              >聊天室管理</b-breadcrumb-item
            >
            <b-breadcrumb-item
              :to="{
                name: 'ChatroomList',
              }"
              >聊天室列表</b-breadcrumb-item
            >
            <b-breadcrumb-item active>聊天室訊息列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-2 mb-xl-2 justify-content-between">
        <div class="col-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">聊天室訊息列表</h4>
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="messages"
        :fields="fields"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(content)="data">
          <div class="content" v-if="data.item.content">
            {{ data.item.content }}
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="messageList"
          @change="(page) => fetchMessages(page)"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import chatroomApi from "../../../apis/chatroomManagement";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";

export default {
  data() {
    return {
      consts,
      isLoading: false,
      messages: [],
      fields: [
        {
          key: "sender_name",
          label: "成員名稱",
        },
        {
          key: "sender_type",
          label: "成員類型",
        },
        {
          key: "content",
          label: "訊息內容",
        },
      ],
      perPage: 15,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    chatroomId() {
      return this.$route.params.chatroomId;
    },
  },
  mounted() {
    this.fetchMessages();
  },
  methods: {
    async fetchMessages(page = 1) {
      try {
        this.isLoading = true;
        const { data } = await chatroomApi.getMessages({
          chatroomId: this.chatroomId,
          page,
          count: this.perPage,
        });
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.messages = Array.isArray(data.data)
          ? data.data.map((message) => ({
              ...message,
              sender_name: message?.sender?.name || "",
              sender_type: message?.sender?.type || message?.sender_type,
            }))
          : [];
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 800px;
  white-space: pre-wrap;
  word-break: break-all;
}
</style>
